import { useIntl } from 'react-intl'
import { titleCaps } from 'utils/universal'
import { applyThousandSeparator } from 'react-number-format/lib/utils'
import { hasOnlyMakerFilterSelected, hasOnlyMakerModelYearFilterSelected } from 'utils/vehicles'

export const getFirst = (arr) => titleCaps(
  arr?.toLocaleString().split('_').sort()?.[0] ?? '',
)

export const getSRPSeo = (filters) => {
  const { formatMessage: f } = useIntl()
  const socialTags = {
    ogUrl: f({ id: 'SEARCH_OGURL' }),
  }

  const formatTypesNameSEOTitle = (name) => {
    if (name.toLowerCase() === 'sedan 4 puertas') {
      return 'Autos Sedan'
    }
    if (name.toLowerCase() === 'crossover') {
      return 'Camionetas Crossover'
    }
    if (name.toLowerCase() === 'coupe 2 puertas') {
      return 'Autos Coupé'
    }
    if (name.toLowerCase() === 'hatchback') {
      return 'Autos Hatchback'
    }
    if (name.toLowerCase() === 'camioneta suv') {
      return 'Camionetas SUV'
    }
    if (name.toLowerCase() === 'van') {
      return 'Camionetas VAN'
    }
    if (name.toLowerCase() === 'convertible') {
      return 'Autos Convertibles'
    }
    if (name.toLowerCase() === 'pickup') {
      return 'Camionetas Pickup'
    }
    if (name.toLowerCase() === 'hibrido-elec') {
      return 'Autos Híbridos'
    }
    return titleCaps(name)
  }

  // if (filters?.maker?.split('_').length === 2 || filters?.model?.split('_').length === 2) {
  //   return {
  //     title: f({ id: 'SEARCH_TITLE' }),
  //     description: f({ id: 'SEARCH_DESCRIPTION' }),
  //     ogUrl: f({ id: 'SEARCH_OGURL' }),
  //     ogSiteName: f({ id: 'SEARCH_OGSITE_NAME' }),
  //     ogType: f({ id: 'SEARCH_OGTYPE' }),
  //     ...socialTags,
  //   }
  // }

  if (filters?.maker) {
    const maker = getFirst(filters?.maker)
    const model = getFirst(filters?.model)
    const year = getFirst(filters?.year)
    const isMakerModelYearFilterSelected = hasOnlyMakerModelYearFilterSelected(filters)
    const isOnlyMakerFilterSelected = hasOnlyMakerFilterSelected(filters)

    return {
      title: f(
        {
          id: isOnlyMakerFilterSelected
            ? 'SEARCH_MAKER_TITLE_SEO'
            : isMakerModelYearFilterSelected ? 'SEARCH_MAKER_MODEL_YEAR_TITLE' : 'SEARCH_MAKER_MODEL_TITLE',
        },
        {
          maker,
          model: !isOnlyMakerFilterSelected && model ? ` ${model}` : '',
          year: !isOnlyMakerFilterSelected && year ? ` ${year}` : '',
        },
      ),
      description: f(
        {
          id:
            isOnlyMakerFilterSelected
              ? 'SEARCH_MAKER_DESCRIPTION_SEO'
              : isMakerModelYearFilterSelected
                ? 'SEARCH_MAKER_MODEL_YEAR_DESCRIPTION'
                : 'SEARCH_MAKER_MODEL_DESCRIPTION',
        },
        {
          maker,
          model: !isOnlyMakerFilterSelected && model ? ` ${model}` : '',
          year: !isOnlyMakerFilterSelected && year ? ` ${year}` : '',
        },
      ),
      ...socialTags,
    }
  }

  if (filters?.year) {
    const year = getFirst(filters.year)
    return {
      title: f({ id: 'SEARCH_YEAR_TITLE' }, { year }),
      description: f({ id: 'SEARCH_YEAR_DESCRIPTION' }, { year }),
      ...socialTags,
    }
  }

  if (filters?.min) {
    const { min } = filters
    const minValue = `$${applyThousandSeparator(min, ',')}`
    return {
      title: f({ id: 'SEARCH_PRICE_TITLE' }, { min: minValue }),
      description: f({ id: 'SEARCH_PRICE_DESCRIPTION' }, { min: minValue }),
      ...socialTags,
    }
  }

  if (filters?.transmission) {
    const { transmission } = filters
    return {
      title: f({ id: 'SEARCH_TRANSMISSION_TITLE' }, { transmission }),
      description: f({ id: 'SEARCH_TRANSMISSION_DESCRIPTION' }, { transmission }),
      ...socialTags,
    }
  }

  if (filters?.body) {
    const type = formatTypesNameSEOTitle(getFirst(filters.body))
    const guaranteed = type.toLocaleLowerCase().includes('auto')
      ? f({ id: 'SEARCH_GUARANTEED_2' })
      : f({ id: 'SEARCH_GUARANTEED_1' })

    const value = `${type} ${guaranteed}`
    return {
      title: f({ id: 'SEARCH_OTHER_VALUE_TITLE' }, { value }),
      description: f({ id: 'SEARCH_OTHER_VALUE_DESCRIPTION' }, { value }),
      ...socialTags,
    }
  }

  if (filters?.fuelType === 'hibrido-elec') {
    const type = formatTypesNameSEOTitle(getFirst(filters.fuelType))
    const guaranteed = f({ id: 'SEARCH_GUARANTEED_2' })
    const value = `${type} ${guaranteed}`
    const title = f({ id: 'SEARCH_OTHER_VALUE_TITLE' }, { value })
    const description = f({ id: 'SEARCH_OTHER_VALUE_DESCRIPTION' }, { value })
    return {
      title,
      description,
      ...socialTags,
    }
  }

  return {
    title: f({ id: 'SEARCH_TITLE' }),
    description: f({ id: 'SEARCH_DESCRIPTION' }),
    ...socialTags,
  }
}
