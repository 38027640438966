import { getFirst } from 'utils/universal/getSRPSeo'
import { formatTypesName, formatYears } from 'utils/miscellaneous'
import { SELLERS_TYPE } from 'enums'
import isEmpty from 'lodash/isEmpty'
import pickBy from 'lodash/pickBy'
import { applyThousandSeparator } from 'react-number-format/lib/utils'

export const getTitleValues = (filters, withYear = false) => {
  if (filters?.maker?.split('_').length === 2 || filters?.model?.split('_').length === 2) {
    return { value: '' }
  }

  const make = getFirst(filters?.maker)
  const model = getFirst(filters?.model)
  const year = withYear && filters?.maker && filters?.model && filters?.year ? getFirst(filters?.year) : ''

  if (!isEmpty(make) || !isEmpty(model) || !isEmpty(year)) {
    return { value: `${make} ${model} ${year}` }
  }
  if (filters?.body) {
    return { value: formatTypesName(getFirst(filters?.body)) }
  }
  return { value: '' }
}

export const cleanFilters = (filters = {}) => {
  if (!filters) return {}

  return Object.keys(filters).reduce((acc, key) => {
    if (filters[key]) {
      acc[key] = `${filters[key]}`.replace(/_/g, ',')
    }

    return acc
  }, {})
}

export const getMakeAndModel = (filters) => {
  const makeCounter = filters?.maker?.split('_')?.length ?? 0
  const make = filters?.maker?.split('_')?.sort()[0] || ''
  const model = makeCounter > 1 ? '' : filters?.model?.split('_')?.sort()[0] || ''
  let url = ''

  if (make !== '') {
    url = `marca-${make}`
  }
  if (model !== '') {
    url += `/modelo-${model}`
  }
  return url
}

// Reduce filters to only contain the first element of any kind
export const getReducedFilters = (filters) => {
  const cleaned = Object.keys(filters).reduce((acc, curr) => {
    acc[curr] = getFirst(filters[curr])
    return acc
  }, {})

  return cleaned
}

export const sortByName = (arr) => arr?.sort(
  (a, b) => a?.name.toLowerCase().localeCompare(b.name.toLowerCase()),
)

export const getSellersOrdered = (arr) => {
  if (arr?.length === 0 || !arr) return []

  const sortArr = arr.map((item) => {
    if (item.name === SELLERS_TYPE.CLIKAUTO) {
      return ({ ...item, position: 1 })
    }
    if (item.name === SELLERS_TYPE.EASY_AGENCY) {
      return ({ ...item, position: 2 })
    }
    return ({
      ...item, position: 3,
    })
  })
  return sortArr
}

export const renameSeller = (name) => {
  if (name.toLowerCase() === 'ClikAuto Easy (Agencia)'.toLowerCase()) {
    return 'ClikAuto Easy: Agencia'
  }
  if (name.toLowerCase() === 'CilkAuto Easy (Particular)'.toLowerCase()) {
    return 'CilkAuto Easy: Particular'
  }
  return name
}

export const formatStateName = (stateName) => {
  if (stateName === 'Michoacan') {
    stateName = 'Michoacán'
  }
  return stateName
}

export const newSellersName = (stateName) => {
  if (stateName.includes('Querétaro')) {
    stateName = 'Querétaro'
  }
  if (stateName.includes('Satélite')) {
    stateName = 'Edo. Méx. Satélite'
  }
  if (stateName.includes('Nápoles')) {
    stateName = 'Nápoles'
  }
  if (stateName.includes('Michoacan') || stateName.includes('Michoacán') || stateName.includes('Morelia')) {
    stateName = 'Michoacán'
  }
  if (stateName.includes('SEMINUEVOSPISTATEXCOCO')) {
    stateName = 'Texcoco'
  }
  // if (stateName.includes('Estado de México')) {
  //   stateName = 'Edo. Méx.'
  // }
  if (stateName.includes('Ciudad de México')) {
    stateName = 'CDMX'
  }
  // if (stateName.includes('Guanajuato')) {
  //   stateName = 'Guanajuato'
  // }
  return stateName
}

const alphabeticMakes = (items) => {
  items.sort((a, b) => {
    return `${a?.name}`.toLowerCase().localeCompare(`${b?.name}`.toLowerCase())
  })
  return items
}

export const orderedMakers = (makes = []) => {
  const volkswagen = makes?.find((m) => `${m?.name}`.toLowerCase() === 'volkswagen')
  const nissan = makes?.find((m) => `${m?.name}`.toLowerCase() === 'nissan')
  const chevrolet = makes?.find((m) => `${m?.name}`.toLowerCase() === 'chevrolet')
  const toyota = makes?.find((m) => `${m?.name}`.toLowerCase() === 'toyota')
  const mazda = makes?.find((m) => `${m?.name}`.toLowerCase() === 'mazda')

  const filteredMakers = makes
    ?.filter((m) => `${m?.name}`?.length > 0)
    ?.filter((m) => !(
      `${m?.name}`.toLowerCase() === 'volkswagen'
      || `${m?.name}`.toLowerCase() === 'nissan'
      || `${m?.name}`.toLowerCase() === 'chevrolet'
      || `${m?.name}`.toLowerCase() === 'toyota'
      || `${m?.name}`.toLowerCase() === 'mazda'
    ))

  let sortedMakes = alphabeticMakes(filteredMakers)
  if (mazda) {
    sortedMakes = [mazda, ...sortedMakes]
  }
  if (toyota) {
    sortedMakes = [toyota, ...sortedMakes]
  }
  if (chevrolet) {
    sortedMakes = [chevrolet, ...sortedMakes]
  }
  if (nissan) {
    sortedMakes = [nissan, ...sortedMakes]
  }
  if (volkswagen) {
    sortedMakes = [volkswagen, ...sortedMakes]
  }
  return sortedMakes
}

export const getAllFiltersSelecteds = (filters) => {
  const arrayFilters = []
  const filtersCopy = { ...filters }

  if (filters?.min || filters?.max) {
    filtersCopy.priceRange = `precio: $${applyThousandSeparator(filters?.min?.toString(), ',')} - $${applyThousandSeparator(filters?.max?.toString(), ',')}`
  }
  delete filtersCopy.min
  delete filtersCopy.max

  if (filters?.year) {
    filtersCopy.year = `${formatYears(filters?.year)}`
  }

  const cleanFiltersObj = pickBy(filtersCopy) // remove all keys void
  if (!isEmpty(cleanFiltersObj)) {
    Object.values(filtersCopy).forEach((filterSelected) => {
      if (filterSelected) {
        `${filterSelected}`.split('_').forEach((i) => arrayFilters.push(i))
      }
    })
  }
  return arrayFilters
}

export const replaceValuesParams = (params = {}) => {
  if (Object.keys(params)?.length === 0) return params

  const newFilter = { ...params }

  if (newFilter?.has_discount?.toLowerCase() === 'descuentos') {
    newFilter.has_discount = true
  }

  if (newFilter?.instant_delivery?.toLowerCase() === 'entregainmediata') {
    newFilter.instant_delivery = true
  }

  if (newFilter?.warranty_and_satisfaction?.toLowerCase() === 'garantiatriple') {
    newFilter.warranty_and_satisfaction = true
  }
  return newFilter
}
